// Generated by ReScript, PLEASE EDIT WITH CARE

import * as P from "../../../styleguide/components/Paragraph/P.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H2 from "../../../styleguide/components/Heading/H2.res.js";
import * as H4 from "../../../styleguide/components/Heading/H4.res.js";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as IconBack from "../../../styleguide/icons/IconBack.res.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as IconPhone from "../../../styleguide/icons/IconPhone.res.js";
import * as Routes_Page from "../../../routes/common/Routes_Page.res.js";
import * as SuccessImage from "../../product-reservation/confirm/SuccessImage.res.js";
import * as Globals from "Common/globals";
import * as JsxRuntime from "react/jsx-runtime";
import * as ThanksForDownloadScss from "./ThanksForDownload.scss";

var css = ThanksForDownloadScss;

function ThanksForDownload$ThanksForDownload(props) {
  var referrer = props.referrer;
  var tmp = referrer === "" ? null : JsxRuntime.jsxs(Button.AsLink.make, {
          href: referrer,
          size: "MD",
          color: "Primary",
          expanded: true,
          className: css.button,
          children: [
            JsxRuntime.jsx(IconBack.make, {
                  size: "SM",
                  color: "White",
                  className: css.buttonIcon
                }),
            "Back to details Page"
          ]
        });
  return JsxRuntime.jsx(Container.make, {
              className: Cx.cx([
                    css.layout,
                    css.additionalPadding
                  ]),
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(SuccessImage.make, {}),
                      JsxRuntime.jsx(H2.make, {
                            className: css.formTitle,
                            children: "Thank you for downloading " + props.filename
                          }),
                      JsxRuntime.jsx(P.make, {
                            children: "One of our specialists will be in contact with you shortly to see if you have questions or need assistance with an upcoming project."
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(H4.make, {
                                    className: css.buttonContainerHeading,
                                    children: "Want to speak with an expert now?"
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs(Button.AsLink.make, {
                                            href: Globals.DCContactNumberHRef,
                                            size: "MD",
                                            color: "Primary",
                                            expanded: true,
                                            className: css.button,
                                            children: [
                                              JsxRuntime.jsx(IconPhone.make, {
                                                    size: "SM",
                                                    color: "White",
                                                    className: css.buttonIcon
                                                  }),
                                              " " + Globals.DCContactNumber
                                            ]
                                          }),
                                      tmp,
                                      JsxRuntime.jsxs(Button.AsLink.make, {
                                            href: Routes_Page.contact,
                                            size: "MD",
                                            color: "Primary",
                                            expanded: true,
                                            className: css.button,
                                            children: [
                                              JsxRuntime.jsx("i", {
                                                    className: "fa fa-comments"
                                                  }),
                                              "Contact Us"
                                            ]
                                          })
                                    ],
                                    className: css.buttonContainer
                                  })
                            ]
                          })
                    ],
                    className: css.formContainer
                  })
            });
}

var ThanksForDownload = {
  css: css,
  make: ThanksForDownload$ThanksForDownload
};

function ThanksForDownload$default(props) {
  return JsxRuntime.jsx(ThanksForDownload$ThanksForDownload, {
              filename: props.filename,
              referrer: props.referrer
            });
}

var $$default = ThanksForDownload$default;

export {
  ThanksForDownload ,
  $$default as default,
}
/* css Not a pure module */
