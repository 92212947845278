// extracted by mini-css-extract-plugin
export var additionalPadding = "ThanksForDownload__additionalPadding__xe9D9";
export var button = "ThanksForDownload__button__vEuVG";
export var buttonContainer = "ThanksForDownload__buttonContainer__eTQ28";
export var buttonContainerHeading = "ThanksForDownload__buttonContainerHeading__fr7Nr";
export var buttonIcon = "ThanksForDownload__buttonIcon__mgdHe";
export var column = "ThanksForDownload__column__LSbdh";
export var flex = "ThanksForDownload__flex__ponM7";
export var flexColumn = "ThanksForDownload__flexColumn__s7VRO";
export var formContainer = "ThanksForDownload__formContainer__eXyrr";
export var formTitle = "ThanksForDownload__formTitle__OBIFD";
export var gap1 = "ThanksForDownload__gap1__UQKTs";
export var gap2 = "ThanksForDownload__gap2__FrkGO";
export var gap3 = "ThanksForDownload__gap3___RSI4";
export var gap4 = "ThanksForDownload__gap4__rGVLq";
export var gap5 = "ThanksForDownload__gap5__bokXc";
export var layout = "ThanksForDownload__layout___dLzG";
export var row = "ThanksForDownload__row___IM4w";